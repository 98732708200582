@import "variables";
@import "print";

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    overflow-x: hidden;

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: Myriad Pro, Arial, sans-serif;
        background: #eaeaea;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

// Centers the header and footer in the middle of the page
.center {
    flex: 0 1 77.5rem;
}

button, input, select {
    appearance: none;
    border: none;
    outline: none;
    background: none;
    margin: 0;
    padding: 0;
}

@import "blocks";

